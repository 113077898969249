import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { IsLoginGuard } from './guards/is-login.guard';
import { IsAdminGuard } from './guards/is-admin.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./pages/system/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      ),
    canActivate: [IsLoginGuard],
  },
  {
    path: 'teams',
    loadChildren: () =>
      import('./pages/teams/teams/teams.module').then((m) => m.TeamsModule),
    canActivate: [IsLoginGuard],
  },
  {
    path: 'project-list',
    loadChildren: () =>
      import('./pages/projects/project-list/project-list.module').then(
        (m) => m.ProjectListModule
      ),
    canActivate: [IsLoginGuard],
  },
  {
    path: 'project-requirements/:id',
    loadChildren: () =>
      import(
        './pages/projects/project-requirements/project-requirements.module'
      ).then((m) => m.ProjectRequirementsModule),
    canActivate: [IsLoginGuard],
  },
  {
    path: 'teams/:team/project/:id',
    loadChildren: () =>
      import('./pages/teams/team-project/team-project.module').then(
        (m) => m.TeamProjectModule
      ),
    canActivate: [IsLoginGuard],
  },
  {
    path: 'teams/:team/project-requirements/:id',
    loadChildren: () =>
      import(
        './pages/teams/team-project-requirement/team-project-requirement.module'
      ).then((m) => m.TeamProjectRequirementModule),
    canActivate: [IsLoginGuard],
  },
  {
    path: 'my-profile',
    loadChildren: () =>
      import('./pages/profile/my-profile/my-profile.module').then(
        (m) => m.MyProfileModule
      ),
    canActivate: [IsLoginGuard],
  },
  {
    path: 'profile/:id',
    loadChildren: () => import('./pages/profile/user-profile/user-profile.module')
      .then(m => m.UserProfileModule)
  },
  {
    path: 'admin/users',
    loadChildren: () =>
      import('./pages/admin/users/users.module').then((m) => m.UsersModule),
    canActivate: [IsLoginGuard, IsAdminGuard],
  },
  {
    path: 'admin/params',
    loadChildren: () =>
      import('./pages/admin/params/params.module').then((m) => m.ParamsModule),
    canActivate: [IsLoginGuard, IsAdminGuard],
  },
  {
    path: 'admin/menus',
    loadChildren: () =>
      import('./pages/admin/menus/menus.module').then(m => m.MenusModule),
    canActivate: [IsLoginGuard, IsAdminGuard]
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./pages/system/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'register',
    loadChildren: () =>
      import('./pages/system/register/register.module').then(
        (m) => m.RegisterModule
      ),
  },
  {
    path: 'my-notifications',
    loadChildren: () => import('./pages/profile/my-notifications/my-notifications.module').then(m => m.MyNotificationsModule),
    canActivate: [IsLoginGuard]
  },
  {
    path: 'my-contacts',
    loadChildren: () => import('./pages/profile/my-contacts/my-contacts.module').then(m => m.MyContactsModule),
    canActivate: [IsLoginGuard]
  },
  {
    path: 'code/angular',
    loadChildren: () => import('./pages/codeGenerator/angular-code/angular-code.module').then(m => m.AngularCodeModule),
    canActivate: [IsLoginGuard]
  },
  {
    path: 'admin/types',
    loadChildren: () => import('./pages/admin/types-projects/types-projects.module').then(m => m.TypesProjectsModule),
    canActivate: [IsLoginGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
