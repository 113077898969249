import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService } from './services/notification/notification.service';
import { LoggerService } from './services/logger/logger.service';
import { MenuService } from './services/menu/menu.service';
import { TypeProjectService } from './services/typeProject/type-project.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {

  idLog = 'AppComponent';
  public isLogin = false;
  public isAdmin = false;
  public user: any;
  public notifications = 0;

  menus: Array<any> = [];

  constructor(
    private router: Router,
    private notificationService: NotificationService,
    private menuService: MenuService,
    private typeProjectService: TypeProjectService,
    private logger: LoggerService
  ) {
    this.getLogin();
    this.getMenus();
    this.getTypesProjects()
  }

  async getLogin() {
    this.user = await JSON.parse(localStorage.getItem('currentUser'));
    console.log({ user1: this.user });
    this.isLogin = await JSON.parse(localStorage.getItem('isUserLoggedIn'));
    this.isAdmin = await JSON.parse(localStorage.getItem('isAdminLoggedIn'));
    if (this.isLogin && this.user) {
      this.notificationService.getReceived(this.user._id)
        .subscribe(res => {
          this.notifications = res.notifications;
          this.logger.log(this.idLog, 'getLogin', { info: 'Success', response: res });
        }, err => {
          this.logger.error(this.idLog, 'getLogin', { info: 'Error', error: err });
        });
    }
  }

  getMenus() {
    this.menuService.getMyMenu()
      .subscribe((res: any) => {
        this.menus = res.menu;
        this.logger.log(this.idLog, 'getMenus', { info: 'Success', response: res });
      }, err => {
        this.logger.error(this.idLog, 'getMenus', { info: 'Error', error: err });
      });
  }

  async getTypesProjects() {
    try {
      const response = await this.typeProjectService.filter({ state: true, name: '' })
      localStorage.setItem('typesProjects', JSON.stringify(response.typesProjects))
      this.logger.log(this.idLog, 'getTypesProjects', { info: 'Success', response })
    } catch (e) {
      this.logger.error(this.idLog, 'getTypesProjects', { info: 'Error', error: e })
    }
  }

  logOut(ev) {
    localStorage.clear();
    this.isLogin = false;
    this.isAdmin = false;
    this.router.navigateByUrl('/login');
  }
}
