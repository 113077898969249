import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  api: String = environment.apiUrl;

  constructor(private http: HttpClient) {}

  get(url) {
    return this.http.get(`${this.api}${url}`);
  }

  post(url, request) {
    return this.http.post(`${this.api}${url}`, request);
  }

  put(url, request) {
    return this.http.put(`${this.api}${url}`, request);
  }

  delete(url) {
    return this.http.delete(`${this.api}${url}`);
  }
}
