import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root'
})
export class TypeProjectService {
  private url1: string = 'api/types-projects/filter';
  private url2: string = 'api/types-projects/save';
  private url3: string = 'api/types-projects/update-icon';
  private url4: string = 'api/types-projects/update';
  private url5: string = 'api/types-projects/delete';

  constructor(
    private api: ApiService
  ) { }


  filter(query): Promise<any> {
    return this.api.get(`${this.url1}?name=${query.name}&state=${query.state}`).toPromise()
  }

  saveTypeProject(request): Promise<any> {
    return this.api.post(`${this.url2}`, request).toPromise()
  }

  updateIcon(request): Promise<any> {
    return this.api.put(`${this.url3}`, request).toPromise()
  }

  updateTypeProject(request): Promise<any> {
    return this.api.put(`${this.url4}`, request).toPromise()
  }

  deleteTypeProject(request): Promise<any> {
    return this.api.delete(`${this.url5}/${request._id}`).toPromise()
  }
}
