import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  private url1 = 'api/menus/filter-menus';
  private url2 = 'api/menus/save-menu';
  private url3 = 'api/menus/update-menu';
  private url4 = 'api/menus/delete-menu/';
  private url5 = 'api/menus/get-my-menu';

  constructor(
    private api: ApiService
  ) { }

  filterMenus(query): Observable<any> {
    return this.api.get(this.url1 + query);
  }

  saveMenu(request): Observable<any> {
    return this.api.post(this.url2, request);
  }

  updateMenu(request): Observable<any> {
    return this.api.put(this.url3, request);
  }

  deleteMenu(id): Observable<any> {
    return this.api.delete(this.url4 + id);
  }

  getMyMenu(){
    return this.api.get(this.url5);
  }
}
