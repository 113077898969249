<app-navbar
  [isLogin]="isLogin"
  [isAdmin]="isAdmin"
  [user]="user"
  [menus]="menus"
  [notifications]="notifications"
  (clickLogOut)="logOut($event)"
></app-navbar>

<router-outlet></router-outlet>
