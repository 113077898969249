import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  @Input() isLogin = false;
  @Input() isAdmin = false;
  @Input() user: any;
  @Input() notifications = 0;
  @Output() clickLogOut: EventEmitter<any> = new EventEmitter();
  @Input() menus: Array<any> = [];

  constructor() {}

  ngOnInit(): void {}

  logOut() {
    this.clickLogOut.emit(true);
  }
}
