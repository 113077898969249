<nav class="navbar navbar-expand-lg navbar-dark bg-primary" *ngIf="isLogin">
  <a class="navbar-brand" routerLink="/">Tecnodesa</a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarColor01"
    aria-controls="navbarColor01" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="navbar-collapse collapse" id="navbarColor01">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item dropdown" *ngFor="let m of menus">
        <a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true"
          aria-expanded="false">{{ m.title }}</a>
        <div class="dropdown-menu">
          <a *ngFor="let r of m.routers" class="dropdown-item" [routerLink]="r.router">{{ r.text }}</a>
        </div>
      </li>
    </ul>
    <ul class="navbar-nav ml-auto">
      <li class="nav-item dropdown">
        <a class="nav-link" style="padding: 0px; margin: 0px" data-toggle="dropdown" href="#" role="button"
          aria-haspopup="true" aria-expanded="false">
          <div *ngIf="user && user.profileImage" [ngStyle]="{
              'background-image': 'url(' + user.profileImage + ')'
            }" class="image"></div>
          <img *ngIf="!user || !user.profileImage" src="assets/imgs/no-profile.jpg" style="
              width: 40px;
              height: 40px;
              border-radius: 50px;
              margin: 0px;
              padding: 0px;
            " alt="" />
        </a>
        <div class="dropdown-menu ml-auto" style="right: 0px">
          <a class="dropdown-item" routerLink="/my-profile">Mi perfil</a>
          <a class="dropdown-item" routerLink="/my-notifications">
            Notificaciones
            <span *ngIf="notifications > 0" class="badge bg-primary rounded-pill"
              style="color: white;">{{notifications}}</span>
          </a>
          <a href="#" class="dropdown-item pointer" [swal]="{
              title: 'Desea cerrar sesion?',
              showCancelButton: 'true',
              cancelButtonText: 'Cancelar'
            }" (confirm)="logOut()">Cerrar sesion</a>
        </div>
      </li>
    </ul>
  </div>
</nav>