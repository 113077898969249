import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private url1 = 'api/notifications/get-by-user';
  private url2 = 'api/notifications/get-received';
  private url3 = 'api/notifications/delete-by-id';
  private url4 = 'api/notifications/accept-by-id';
  private url5 = 'api/notifications/reject-by-id';

  constructor(private api: ApiService) { }

  getByUser(userId): Observable<any> {
    return this.api.get(`${this.url1}?id=${userId}`);
  }

  getReceived(userId): Observable<any> {
    return this.api.get(`${this.url2}?id=${userId}`);
  }

  delete(request): Observable<any> {
    return this.api.put(`${this.url3}`, request);
  }

  accept(request): Promise<any> {
    return this.api.put(`${this.url4}`, request).toPromise()
  }

  reject(request): Observable<any> {
    return this.api.put(`${this.url5}`, request);
  }
}
