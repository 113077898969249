import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class IsAdminGuard implements CanActivate {
  async canActivate() {
    const isAdmin = await localStorage.getItem('isAdminLoggedIn');
    if (isAdmin) {
      return true;
    } else {
      return false;
    }
  }
}
